import "./style.scss";

window.$ = require('jquery');
// アンカースクロール
function scrollToAnchor(hash, $header) {
  let $target = $(hash);
  if ($target.length > 0) {
    let pos = $target.offset().top;
    if (pos > 0) {
      pos -= $header.outerHeight();
      pos -= 120;
      if (pos < 300) pos = 300;
    }
    $('body, html').stop().animate({
      scrollTop: pos
    }, 500);
  }
}

$(() => {
  let isTouchDevice = (window.ontouchstart === null);
  if (isTouchDevice) {
    $("body").addClass("is_touchDevice");
  } else {
    $("body").addClass("is_notTouchDevice");
    $("head").append('<link rel="stylesheet" src="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap">');
  }
  // SPメニュー時の固定処理
  let no_action;
  let _$header = $("#masthead");
  let _$sp_menu_trigger = $(".menu-trigger");
  let _$body = $("html, body");
  _$sp_menu_trigger.on("click", () => {
    let checked = $("#primary-navigation-checkbox").is(":checked");
    if (checked) {
      no_action = false;
      let scrollY = _$body.css("top");
      _$body.css({
        position: "",
        top: ""
      });
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    } else {
      no_action = true;
      let scrollY = $(window).scrollTop();
      _$body.css({
        position: 'fixed',
        top: -scrollY
      });
    }
  });

  let _$pagetop = $("#page-top");
  $(document).on("scroll", function () {
    let st = $(window).scrollTop();
    if (no_action) return;
    if (st > 300) {
      _$header.addClass("active");
      _$pagetop.fadeIn();
    } else {
      _$header.removeClass("active");
      _$pagetop.fadeOut();
    }
  });

  // ハッシュ値があればページ内スクロール
  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      scrollToAnchor(urlHash, _$header);
    }, 100);
  }

  $('.js-scroll').on("click", function () {
    let href = $(this).attr("href");
    let hash = href == "#" || href == "" ? "html" : href;
    scrollToAnchor(hash, _$header);
    return false;
  });

  // tabメニュー処理（採用情報）
  let _$tab_menu = $(".js-tab-menu");
  let _$tab_content = $(".tab-content");
  _$tab_menu.on("click", function () {
    if (!$(this).hasClass("active")) {
      let target = $(this).data("content");
      _$tab_menu.removeClass("active");
      $(this).addClass("active");
      _$tab_content.removeClass("active");
      $("#" + target).addClass("active");
    }
  });
});